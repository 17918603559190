// routes
import { paths } from 'src/routes/paths';

// API
// ----------------------------------------------------------------------

const hostname = window.location.hostname;
const PROD_URL = 'contabilidade.truetech.co.mz';
const DEV_URL = 'contabilidade.ttmlive.com';

let hostApi = '';

switch (hostname) {
    case PROD_URL:
        hostApi  = 'https://api-contabilidade.ttmlive.com';
        break;
    case DEV_URL:
        hostApi = 'https://api-contabilidade.ttmlive.com';
        break;
    default:
        hostApi = 'http://localhost:8080/';
        break;
}

 export const HOST_API = hostApi;

export const ASSETS_API = import.meta.env.VITE_ASSETS_API;

// ROOT PATH AFTER LOGIN SUCCESSFUL
export const PATH_AFTER_LOGIN = paths.dashboard.root; // as '/dashboard'


import { useMemo } from 'react';
// routes
import { paths } from 'src/routes/paths';
// locales
import { useLocales } from 'src/locales';
// components
import SvgColor from 'src/components/svg-color';
import {Authorities} from "../../utils/authorities";
import { Icon } from '@iconify/react';
import Label from 'src/components/label';
// ----------------------------------------------------------------------

const icon = (name: string) => (
    <SvgColor src={`/assets/icons/navbar/${name}.svg`} sx={{ width: 1, height: 1 }} />
);


const ICONS = {
  mail: icon('ic_mail'),
  user: icon('ic_user'),
  label: icon('ic_label'),
  blank: icon('ic_blank'),
  invoice: icon('ic_invoice'),
  product: icon('ic_product'),
  calendar: icon('ic_calendar'),
  disabled: icon('ic_disabled'),
  external: icon('ic_external'),
  ecommerce: icon('ic_ecommerce'),
  menuItem: icon('ic_menu_item'),
  dashboard: icon('ic_dashboard'),
  folder: icon('ic_folder'),
  banking: icon('ic_banking'),
  booking: icon('ic_booking'),
};

// ----------------------------------------------------------------------

export function useNavData() {
  const { t } = useLocales();


  const data = useMemo(
      () => [
        // OVERVIEW
        // ----------------------------------------------------------------------
        {
          subheader: t('visão geral'),
          items: [
            {
              title: t('dashboard'),
              roles: [Authorities.ROLE_ADMIN, Authorities.ROLE_BL],
              path: paths.dashboard.root,
              icon: ICONS.dashboard
            },
            {
              title: t('balancete'),
              roles: [Authorities.ROLE_ADMIN, Authorities.ROLE_BL],
              path: paths.dashboard.root,
              icon: <Icon icon="carbon:scales" />,
            },
            {
              title: t('perfil'),
              roles: [Authorities.ROLE_BL],
              // path: paths.businessLocation.general.profile,
              path: '#',
              icon: <Icon icon="ant-design:user-outlined" />
            },
          ],
        },

        {
          subheader: '',
          items: [
            {
              title: 'Cadastros',
              path: paths.dashboard.customer.root,
              icon: <Icon icon="carbon:edit" />,
              roles: [Authorities.ROLE_ADMIN],
              children: [
                {
                  roles: [Authorities.ROLE_ADMIN, Authorities.READ_ACCOUNT],
                  title: 'Plano de contas',
                  path: paths.dashboard.account.list
                },
                {
                  roles: [Authorities.ROLE_ADMIN, Authorities.READ_COSTCENTERS],
                  title: 'Centros de custo',
                  path: paths.dashboard.costcenter.list
                },
                {
                  roles: [Authorities.ROLE_ADMIN, Authorities.READ_CUSTOMERS],
                  title: 'Clientes',
                  path: paths.dashboard.customer.list
                }
              ],
            },
            {
              title: 'Diários',
              path: paths.dashboard.customer.root,
              icon: <Icon icon="carbon:request-quote" />,
              roles: [Authorities.ROLE_ADMIN],
              children: [
                {
                  roles: [Authorities.ROLE_ADMIN, Authorities.READ_ACCOUNT],
                  title: 'Adicionar lançamento',
                  path: paths.dashboard.journal.new
                },
                {
                  roles: [Authorities.ROLE_ADMIN, Authorities.READ_COSTCENTERS],
                  title: 'Visualizar lançamentos',
                  path: paths.dashboard.journal.list
                }
              ],
            },
            {
              title: 'Extratos',
              path: paths.dashboard.customer.root,
              icon: <Icon icon="carbon:report" />,
              roles: [Authorities.ROLE_ADMIN],
              children: [
                {
                  roles: [Authorities.ROLE_ADMIN, Authorities.READ_ACCOUNT],
                  title: 'Gerar extrato',
                  path: paths.dashboard.account.list
                }
              ],
            },
          ]
        },
        // MANAGEMENT
        // ----------------------------------------------------------------------

        // BILLING
        // ----------------------------------------------------------------------

        // SETTINGS
        // ----------------------------------------------------------------------

        {
          subheader: t('administrador'),
          items: [
            {
              title: t('gestão de utilizadores'),
              icon: <Icon icon="mdi:user" />,
              path: paths.dashboard.user.root,
              roles: [Authorities.ROLE_ADMIN],
              children: [
                {
                  roles: [Authorities.ROLE_ADMIN, Authorities.READ_USERS],
                  title: 'listar',
                  path: paths.dashboard.user.list,
                },{
                  roles: [Authorities.ROLE_ADMIN, Authorities.WRITE_USER],
                  title: 'novo utilizador',
                  path: paths.dashboard.user.new,
                },
                {
                  roles: [Authorities.ROLE_ADMIN, Authorities.READ_ROLES],
                  title: 'cargos',
                  path: paths.dashboard.role.list,
                }, {
                  roles: [Authorities.ROLE_ADMIN, Authorities.READ_PERMISSIONS],
                  title: 'permissões',
                  path: paths.dashboard.permission.list,
                },
              ],
            },

          ]
        },
      ],
      [t]
  );

  return data;
}

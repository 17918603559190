// routes
import { paths } from 'src/routes/paths';
// utils
import axios from 'src/utils/axios';
import {fetchBaseQuery} from "@reduxjs/toolkit/query";
import {HOST_API} from "../../../config-global";
import { Authorities } from '../../../utils/authorities';

// ----------------------------------------------------------------------

export function jwtDecode(token: string | null) {
  if (!token) {
    return { authorities: [] };
  }
  const base64Url = token.split('.')[1];
  const base64 = base64Url.replace(/-/g, '+').replace(/_/g, '/');
  const jsonPayload = decodeURIComponent(
    window
      .atob(base64)
      .split('')
      .map((c) => `%${`00${c.charCodeAt(0).toString(16)}`.slice(-2)}`)
      .join('')
  );

  return JSON.parse(jsonPayload);
}

// ----------------------------------------------------------------------

export const isValidToken = (accessToken: string) => {
  if (!accessToken) {
    return false;
  }

  const decoded = jwtDecode(accessToken);

  const currentTime = Date.now() / 1000;

  return decoded.exp > currentTime;
};

// ----------------------------------------------------------------------

export const tokenExpired = (exp: number) => {
  // eslint-disable-next-line prefer-const
  let expiredTimer;

  const currentTime = Date.now();

  // Test token expires after 10s
  // const timeLeft = currentTime + 10000 - currentTime; // ~10s
  const timeLeft = exp * 1000 - currentTime;

  clearTimeout(expiredTimer);

  expiredTimer = setTimeout(() => {
    sessionStorage.removeItem('accessToken');

    window.location.href = paths.auth.jwt.login;
  }, timeLeft);
};

// ----------------------------------------------------------------------
let getAccessToken: string = sessionStorage.getItem('accessToken') || '';
// ----------------------------------------------------------------------

export const setSession = async (accessToken: string | null) => {
  if (accessToken) {
    getAccessToken = accessToken;
    sessionStorage.setItem('accessToken', accessToken);

    axios.defaults.headers.common.Authorization = `Bearer ${accessToken}`;

    // This function below will handle when token is expired
    const { exp } = jwtDecode(accessToken); // ~3 days by minimals server
    tokenExpired(exp);
  } else {
    sessionStorage.removeItem('accessToken');

    delete axios.defaults.headers.common.Authorization;
  }
};

export const customBaseQuery = fetchBaseQuery({
  baseUrl: HOST_API,
  prepareHeaders: (headers, { getState }) => {
    // Add the Authorization header here
    if (getAccessToken) {
      headers.set('Authorization', `Bearer ${getAccessToken}`);
      headers.set('Content-Type', 'application/json');
    }
    return headers;
  },
});

export const hasPermission = (main : string[], subList : string[]) : boolean => main.some((searchItem) => subList.includes(searchItem));
export const isAdmin = () => {
    const accessToken = sessionStorage.getItem("accessToken");
    if (accessToken && isValidToken(accessToken)) {
      const { authorities } = jwtDecode(accessToken);
      return authorities.includes(Authorities.ROLE_ADMIN);
    }
}


//******************************************************************************************** *//


import { lazy, Suspense } from 'react';
import { Outlet } from 'react-router';
import {AuthGuard, RoleBasedGuard} from 'src/auth/guard';
import { LoadingScreen } from 'src/components/loading-screen';
import DashboardLayout from 'src/layouts/dashboard/layout';
import {Authorities} from "../../utils/authorities";


// ----------------------------------------------------------------------


// Customers
const CustomerListPage = lazy(() => import('../../pages/customers/customerlist'));
const CustomerNewPage = lazy(() => import('../../pages/customers/customercreate'));
const CustomerEditPage = lazy(() => import('../../pages/customers/customeredit'));


export const customerRoutes = [

  {
    path: 'customers',
    element: (
        <AuthGuard>
          <DashboardLayout>
            <Suspense fallback={<LoadingScreen />}>
              <Outlet />
            </Suspense>
          </DashboardLayout>
        </AuthGuard>
    ),
    children: [
      {path: 'list', element: <RoleBasedGuard roles={[Authorities.ROLE_ADMIN, Authorities.READ_CUSTOMERS]} hasContent><CustomerListPage/></RoleBasedGuard>},
      {path: 'new', element: <RoleBasedGuard roles={[Authorities.ROLE_ADMIN, Authorities.WRITE_CUSTOMER]} hasContent><CustomerNewPage/></RoleBasedGuard>},
      {path: ':id/edit', element: <RoleBasedGuard roles={[Authorities.ROLE_ADMIN, Authorities.EDIT_CUSTOMER]} hasContent><CustomerEditPage/></RoleBasedGuard>},
    ],
  },
]

import {Navigate, useRoutes} from 'react-router-dom';
// layouts
import MainLayout from 'src/layouts/main';
// config
//
import {authRoutes} from './auth';
import {dashboardRoutes} from './dashboard';
import {HomePage, mainRoutes} from "./main";
import {customerRoutes} from "./customer";
import {accountRoutes} from "./account";
import {journalRoutes} from "./journal";

// ----------------------------------------------------------------------

export default function Router() {
  return useRoutes([

    {
      path: '/',
      element: (
          <MainLayout>
            <HomePage />
          </MainLayout>
      ),
    },

    // ----------------------------------------------------------------------

    // Auth routes
    ...authRoutes,

    // Dashboard routes
    ...dashboardRoutes,

    // Customer routes
    ...customerRoutes,

    // Main routes
    ...mainRoutes,

    ...accountRoutes,

      ...journalRoutes,

    // No match 404
    { path: '*', element: <Navigate to="/404" replace /> },
  ]);
}

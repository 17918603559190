import {configureStore} from "@reduxjs/toolkit";
import {setupListeners} from "@reduxjs/toolkit/query";
import {persistReducer, persistStore} from "redux-persist";
import rootReducer, {rootPersistConfig} from "./rootReducer";
import {permissionService} from "./services/permissionService";
import {roleService} from "./services/roleService";
import {userService} from "./services/userService";
import { commonService } from "./services/commonService";
import {customerService} from "./services/customerService";
import {accountService} from "./services/accountService";
import {costCenterService} from "./services/costcenterService";
import {journalService} from "./services/journalService";
import {journalTypeService} from "./services/journalTypeService";

// const isDevelopment = process.env.NODE_ENV === 'development';

export const store = configureStore({
    reducer: persistReducer(rootPersistConfig, rootReducer),
    middleware: (getDefaultMiddleware) =>
        getDefaultMiddleware({
            immutableCheck: false, // Disable immutable check middleware
            serializableCheck: false, // Disable the serializable check middleware
        })
            .concat(permissionService.middleware)
            .concat(roleService.middleware)
            .concat(userService.middleware)
            .concat(customerService.middleware)
            .concat(accountService.middleware)
            .concat(costCenterService.middleware)
            .concat(commonService.middleware)
            .concat(journalService.middleware)
            .concat(journalTypeService.middleware)

});

const persistor = persistStore(store);
setupListeners(store.dispatch);

export type RootState = ReturnType<typeof store.getState>
export type AppDispatch = typeof store.dispatch;
export { persistor };

import { createApi } from '@reduxjs/toolkit/query/react'
import {IJournal, IJournalDeleteResponse, IJournalPage} from "../../types/journal";
import {customBaseQuery, isValidToken} from "../../auth/context/jwt/utils";
import axios, {endpoints} from "../../utils/axios";

const path: string = 'journals'

type UpdateProps = {
    data: IJournal,
    id: number
}

export const journalService = createApi({
    reducerPath: 'journalService',
    tagTypes: ['journals'],
    baseQuery: customBaseQuery,
    endpoints: (builder) => ({
        getJournals: builder.query<IJournalPage, IJournalPage>({
            query: (page : IJournalPage) => ({
                url: `/${path}/list`,
                params: { ...page, content: [] }
            }),
            providesTags: ['journals']
        }),
        getJournal: builder.mutation<IJournal, number>({
            query: (id: number) => ({
                url: `/${path}/findById/${id}`,
                method: 'GET'
            })
        }),
        saveJournal: builder.mutation<IJournal, IJournal>({
            query: (journal: IJournal) => ({
                url: `/${path}/insert`,
                method: 'POST',
                body: journal,
                headers: {'Content-Type': 'multipart/form-data'}
            }),
            invalidatesTags: ['journals'],
        }),
        updateJournal: builder.mutation<IJournal, IJournal>({
            query: (data: IJournal) => ({
                url: `/${path}/update`,
                method: 'PUT',
                body: data,
                headers: {'Content-Type': 'multipart/form-data'}
            }),
            invalidatesTags: ['journals']
        }),
        deleteJournal: builder.mutation<IJournalDeleteResponse, number>({
            query: (id: number) => ({
                url: `/${path}/deleteById/${id}`,
                method: 'DELETE'
            }),
            invalidatesTags: ['journals']
        })
    }),
})

export const {
    useGetJournalsQuery,
    useSaveJournalMutation,
    useDeleteJournalMutation,
    useGetJournalMutation,
    useUpdateJournalMutation,
} = journalService

export const generateJournalReference = async () => {
    const accessToken = sessionStorage.getItem("accessToken");

    if (accessToken && isValidToken(accessToken)) {

        const res = await axios
            .get(`${endpoints.common.journals}/generateNextJournalReference`,
                {headers: { Authorization: `Bearer ${accessToken}`}});

        return res.data;
    }
    return "";
}

import { combineReducers } from 'redux';
import { persistReducer } from 'redux-persist';
import storage from 'redux-persist/lib/storage';
// slices
import permissionReducer from './slices/permissionSlice';
import roleReducer from './slices/roleSlice';
import userReducer from './slices/userSlice';
// services
import {permissionService} from "./services/permissionService";
import {roleService} from "./services/roleService";
import {userService} from "./services/userService";
import {commonService} from "./services/commonService";
import {accountService} from "./services/accountService";
import {customerService} from "./services/customerService";
import {costCenterService} from "./services/costcenterService";
import {journalService} from "./services/journalService";
import {journalTypeService} from "./services/journalTypeService";
// ----------------------------------------------------------------------

export const rootPersistConfig = {
  key: 'root',
  storage,
  keyPrefix: 'redux-',
  whitelist: [],
};

export const permissionPersistConfig = {
  key: 'permission',
  storage,
  keyPrefix: 'redux-',
  whitelist: [],
};

export const costcenterPersistConfig = {
  key: 'costcenter',
  storage,
  keyPrefix: 'redux-',
  whitelist: [],
};

export const rolePersistConfig = {
  key: 'role',
  storage,
  keyPrefix: 'redux-',
  whitelist: [],
};

export const userPersistConfig = {
  key: 'user',
  storage,
  keyPrefix: 'redux-',
  whitelist: [],
};

export const commonPersistConfig = {
  key: 'common',
  storage,
  keyPrefix: 'redux-',
  whitelist: [],
};
export const customerPersistConfig = {
  key: 'customer',
  storage,
  keyPrefix: 'redux-',
  whitelist: [],
};
export const accountPersistConfig = {
  key: 'account',
  storage,
  keyPrefix: 'redux-',
  whitelist: [],
};
export const journalPersistConfig = {
  key: 'journal',
  storage,
  keyPrefix: 'redux-',
  whitelist: [],
};

export const journalTypePersistConfig = {
  key: 'journalType',
  storage,
  keyPrefix: 'redux-',
  whitelist: [],
};

const rootReducer = combineReducers({
  permission: persistReducer(permissionPersistConfig, permissionReducer),
  role: persistReducer(rolePersistConfig, roleReducer),
  user: persistReducer(userPersistConfig, userReducer),
  common: persistReducer(commonPersistConfig, commonService.reducer),
  account: persistReducer(accountPersistConfig, accountService.reducer),
  customer: persistReducer(customerPersistConfig, customerService.reducer),
  costcenter: persistReducer(costcenterPersistConfig, costCenterService.reducer),
  journal: persistReducer(journalPersistConfig, journalService.reducer),
  [permissionService.reducerPath]: permissionService.reducer,
  [roleService.reducerPath]: roleService.reducer,
  [userService.reducerPath]: userService.reducer,
  [commonService.reducerPath]: commonService.reducer,
  [accountService.reducerPath]: accountService.reducer,
  [customerService.reducerPath]: customerService.reducer,
  [costCenterService.reducerPath]: costCenterService.reducer,
    [journalService.reducerPath]: journalService.reducer,
    [journalTypeService.reducerPath]: journalTypeService.reducer,
});

export default rootReducer;

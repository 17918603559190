import { createApi } from '@reduxjs/toolkit/query/react'
import {IJournalType, IJournalTypeDeleteResponse, IJournalTypePage} from "../../types/journaltype";
import {customBaseQuery} from "../../auth/context/jwt/utils";

const path: string = 'journalTypes'

type UpdateProps = {
    data: IJournalType,
    id: number
}

export const journalTypeService = createApi({
    reducerPath: 'journalTypeService',
    tagTypes: ['journalTypes'],
    baseQuery: customBaseQuery,
    endpoints: (builder) => ({
        getJournalTypes: builder.query<IJournalType[], void>({
            query: () => ({
                url: `/${path}/findAll`,
            }),
            providesTags: ['journalTypes']
        }),
        getJournalType: builder.mutation<IJournalType, number>({
            query: (id: number) => ({
                url: `/${path}/findById/${id}`,
                method: 'GET'
            })
        }),
        saveJournalType: builder.mutation<IJournalType, IJournalType>({
            query: (journalType: IJournalType) => ({
                url: `/${path}/insert`,
                method: 'POST',
                body: journalType,
                headers: {'Content-Type': 'multipart/form-data'}
            }),
            invalidatesTags: ['journalTypes'],
        }),
        updateJournalType: builder.mutation<IJournalType, Partial<UpdateProps>>({
            query: (data: UpdateProps) => ({
                url: `/${path}/update`,
                method: 'PUT',
                body: data.data,
                headers: {'Content-Type': 'multipart/form-data'}
            }),
            invalidatesTags: ['journalTypes']
        }),
        deleteJournalType: builder.mutation<IJournalTypeDeleteResponse, number>({
            query: (id: number) => ({
                url: `/${path}/deleteById/${id}`,
                method: 'DELETE'
            }),
            invalidatesTags: ['journalTypes']
        })
    }),
})

export const {
    useGetJournalTypesQuery,
    useSaveJournalTypeMutation,
    useDeleteJournalTypeMutation,
    useGetJournalTypeMutation,
    useUpdateJournalTypeMutation,
} = journalTypeService

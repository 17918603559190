import { createApi } from '@reduxjs/toolkit/query/react'
import {ICustomer, ICustomerCreate, ICustomerDeleteResponse, ICustomerPage} from "../../types/customer";
import {customBaseQuery} from "../../auth/context/jwt/utils";

const path: string = 'customers'

type UpdateProps = {
    data: ICustomerCreate,
    id: number
}

export const customerService = createApi({
    reducerPath: 'customerService',
    tagTypes: ['customers'],
    baseQuery: customBaseQuery,
    endpoints: (builder) => ({
        getCustomers: builder.query<ICustomerPage, ICustomerPage>({
            query: (page : ICustomerPage) => ({
                url: `/${path}/list`,
                params: { ...page, content: [] }
            }),
            providesTags: ['customers']
        }),
        getCustomer: builder.mutation<ICustomer, number>({
            query: (id: number) => ({
                url: `/${path}/findById/${id}`,
                method: 'GET'
            })
        }),
        saveCustomer: builder.mutation<ICustomer, ICustomerCreate>({
            query: (customer: ICustomerCreate) => ({
                url: `/${path}/insert`,
                method: 'POST',
                body: customer,
                headers: {'Content-Type': 'multipart/form-data'}
            }),
            invalidatesTags: ['customers'],
        }),
        updateCustomer: builder.mutation<ICustomer, Partial<UpdateProps>>({
            query: (data: UpdateProps) => ({
                url: `/${path}/update`,
                method: 'PUT',
                body: data.data,
                headers: {'Content-Type': 'multipart/form-data'}
            }),
            invalidatesTags: ['customers']
        }),
        deleteCustomer: builder.mutation<ICustomerDeleteResponse, number>({
            query: (id: number) => ({
                url: `/${path}/deleteById/${id}`,
                method: 'DELETE'
            }),
            invalidatesTags: ['customers']
        })
    }),
})

export const {
    useGetCustomersQuery,
    useSaveCustomerMutation,
    useDeleteCustomerMutation,
    useGetCustomerMutation,
    useUpdateCustomerMutation,
} = customerService

import * as Yup from 'yup';
import { useForm } from 'react-hook-form';
import { yupResolver } from '@hookform/resolvers/yup';
// @mui
import LoadingButton from '@mui/lab/LoadingButton';
import Stack from '@mui/material/Stack';
import IconButton from '@mui/material/IconButton';
import Typography from '@mui/material/Typography';
import InputAdornment from '@mui/material/InputAdornment';
// hooks
import { useBoolean } from 'src/hooks/use-boolean';
// assets
import {PasswordIcon} from 'src/assets/icons';
// components
import Iconify from 'src/components/iconify';
import FormProvider, { RHFTextField, RHFCode } from 'src/components/hook-form';
import {useSetPasswordMutation} from "../../../redux/services/userService";
import {enqueueSnackbar} from "notistack";

// ----------------------------------------------------------------------

export default function SetPasswordView() {
    const password = useBoolean();

    const [setPassword] = useSetPasswordMutation();

    const NewPasswordSchema : any = Yup.object().shape({
        password: Yup.string()
            .min(6, 'A senha deve conter no minímo 6 caracteres.')
            .required('A senha é obrigatória'),
        passwordConfirm: Yup.string()
            .required('A confirmação é senha é obrigatória')
            .oneOf([Yup.ref('password')], 'As senhas devem ser iguais'),
    });

    const defaultValues = {
        password: '',
        passwordConfirm: '',
    };

    const methods = useForm({
        mode: 'onChange',
        resolver: yupResolver(NewPasswordSchema),
        defaultValues,
    });

    const {
        handleSubmit,
        formState: { isSubmitting },
        reset
    } = methods;

    const onSubmit = handleSubmit(async (data) => {
        try {
            await setPassword(data).unwrap();
            reset();
            await sessionStorage.removeItem('hasNoChangedPassword');
            enqueueSnackbar('Senha alterada com sucesso');

        } catch (error) {
            reset();
            enqueueSnackbar(error.status === 500 ?
                        "Ocorreu um erro inesperado" :
                        "Verifique os dados introduzidos.",
                    {variant: "error"});
        }
    });

    const renderForm = (
        <Stack spacing={3} alignItems="center">

            <RHFTextField
                name="password"
                label="Senha"
                type={password.value ? 'text' : 'password'}
                InputProps={{
                    endAdornment: (
                        <InputAdornment position="end">
                            <IconButton onClick={password.onToggle} edge="end">
                                <Iconify icon={password.value ? 'solar:eye-bold' : 'solar:eye-closed-bold'} />
                            </IconButton>
                        </InputAdornment>
                    ),
                }}
            />

            <RHFTextField
                name="passwordConfirm"
                label="Confirmar nova senha"
                type={password.value ? 'text' : 'password'}
                InputProps={{
                    endAdornment: (
                        <InputAdornment position="end">
                            <IconButton onClick={password.onToggle} edge="end">
                                <Iconify icon={password.value ? 'solar:eye-bold' : 'solar:eye-closed-bold'} />
                            </IconButton>
                        </InputAdornment>
                    ),
                }}
            />

            <LoadingButton
                fullWidth
                size="large"
                type="submit"
                variant="contained"
                loading={isSubmitting}
            >
                Actualizar senha
            </LoadingButton>

        </Stack>
    );

    const renderHead = (
        <>
            <PasswordIcon sx={{ height: 96 }} />

            <Stack spacing={1} sx={{ my: 5 }}>
                <Typography variant="h3">Proteção da conta!</Typography>

                <Typography variant="body2" sx={{ color: 'text.secondary' }}>
                    Por forma a garantir a sua privacidade no sistema, deve definir uma nova senha!
                </Typography>
            </Stack>
        </>
    );

    return (
        <FormProvider methods={methods} onSubmit={onSubmit}>
            {renderHead}

            {renderForm}
        </FormProvider>
    );
}

import { createApi } from '@reduxjs/toolkit/query/react'
import {IAccount, IAccountDeleteResponse, IAccountPage} from "../../types/account";
import {customBaseQuery, isValidToken} from "../../auth/context/jwt/utils";
import axios, {endpoints} from "../../utils/axios";

const path: string = 'accounts'

type UpdateProps = {
    data: IAccount,
    id: number
}

export const accountService = createApi({
    reducerPath: 'accountService',
    tagTypes: ['accounts'],
    baseQuery: customBaseQuery,
    endpoints: (builder) => ({
        getAccounts: builder.query<IAccountPage, IAccountPage>({
            query: (page : IAccountPage) => ({
                url: `/${path}/list`,
                params: { ...page, content: [] }
            }),
            providesTags: ['accounts']
        }),
        getAccount: builder.mutation<IAccount, number>({
            query: (id: number) => ({
                url: `/${path}/findById/${id}`,
                method: 'GET'
            })
        }),
        saveAccount: builder.mutation<IAccount, IAccount>({
            query: (account: IAccount) => ({
                url: `/${path}/insert`,
                method: 'POST',
                body: account,
                headers: {'Content-Type': 'multipart/form-data'}
            }),
            invalidatesTags: ['accounts'],
        }),
        updateAccount: builder.mutation<IAccount, Partial<UpdateProps>>({
            query: (data: UpdateProps) => ({
                url: `/${path}/update`,
                method: 'PUT',
                body: data,
                headers: {'Content-Type': 'multipart/form-data'}
            }),
            invalidatesTags: ['accounts']
        }),
        deleteAccount: builder.mutation<IAccountDeleteResponse, number>({
            query: (id: number) => ({
                url: `/${path}/deleteById/${id}`,
                method: 'DELETE'
            }),
            invalidatesTags: ['accounts']
        })
    }),
})

export const {
    useGetAccountsQuery,
    useSaveAccountMutation,
    useDeleteAccountMutation,
    useGetAccountMutation,
    useUpdateAccountMutation,
} = accountService


export const generateAccountCode = async (parentId : number) => {
    const accessToken = sessionStorage.getItem("accessToken");

    if (accessToken && isValidToken(accessToken)) {

        const res = await axios
            .get(`${endpoints.common.accounts}/generateCode/${parentId}`,
                {headers: { Authorization: `Bearer ${accessToken}`}});

        return res.data;
    }
    return "";
}


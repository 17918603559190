import { createApi } from '@reduxjs/toolkit/query/react'
import {IUser, IUserCreate, IUserDeleteResponse, IUserPage} from "../../types/user";
import {customBaseQuery} from "../../auth/context/jwt/utils";

const path: string = 'users'

type UpdateProps = {
    data: IUserCreate,
    id: number
}

type PasswordReset = {
    passwordConfirm: string,
    password: string,
    id?: number
}

export const userService = createApi({
    reducerPath: 'userService',
    tagTypes: ['users'],
    baseQuery: customBaseQuery,
    endpoints: (builder) => ({
        getUsers: builder.query<IUserPage, IUserPage>({
            query: (page : IUserPage) => ({
                url: `/${path}/list`,
                params: { ...page, content: [] }
            }),
            providesTags: ['users']
        }),
        getUser: builder.mutation<IUser, number>({
            query: (id: number) => ({
                url: `/${path}/findById/${id}`,
                method: 'GET'
            })
        }),
        saveUser: builder.mutation<IUser, IUserCreate>({
            query: (user: IUserCreate) => ({
                url: `/${path}/insert`,
                method: 'POST',
                body: user,
                headers: {'Content-Type': 'multipart/form-data'}
            }),
            invalidatesTags: ['users'],
        }),
        setPassword: builder.mutation<string, PasswordReset>({
            query: (creds: PasswordReset) => ({
                url: `/${path}/setPassword`,
                method: 'POST',
                body: creds
            }),
            invalidatesTags: ['users'],
        }),
        updateUser: builder.mutation<IUser, Partial<UpdateProps>>({
            query: (data: UpdateProps) => ({
                url: `/${path}/update/${data.id}`,
                method: 'PUT',
                body: data,
                headers: {'Content-Type': 'multipart/form-data'}
            }),
            invalidatesTags: ['users']
        }),
        deleteUser: builder.mutation<IUserDeleteResponse, number>({
            query: (id: number) => ({
                url: `/${path}/deleteById/${id}`,
                method: 'DELETE'
            }),
            invalidatesTags: ['users']
        })
    }),
})

export const {
    useGetUsersQuery,
    useSaveUserMutation,
    useDeleteUserMutation,
    useGetUserMutation,
    useUpdateUserMutation,
    useSetPasswordMutation
} = userService

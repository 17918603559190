import {useCallback, useEffect, useState} from 'react';
// routes
import {paths} from 'src/routes/paths';
import {useRouter} from 'src/routes/hook';
//
import {useAuthContext} from '../hooks';
import { isValidToken, jwtDecode, setSession } from '../context/jwt/utils';

import SetPasswordPage from "../../pages/auth/jwt/set-password";

// ----------------------------------------------------------------------

const loginPaths: Record<string, string> = {
  jwt: paths.auth.jwt.login,
};

// ----------------------------------------------------------------------

type Props = {
  children: React.ReactNode;
};

export default function AuthGuard({ children }: Props) {
  const router = useRouter();

  let authenticated = false;
  let hasNoChangedPassword = false;
  // Pegar a token
  const accessToken = sessionStorage.getItem("accessToken");
  if (accessToken && isValidToken(accessToken)) {
      setSession(accessToken);
      authenticated = true;
  }

  const passwordChangedAt = sessionStorage.getItem("hasNoChangedPassword");
  if (passwordChangedAt) {
    hasNoChangedPassword = true;
  }
  //

  const { method } = useAuthContext();

  const [checked, setChecked] = useState(false);

  const check = useCallback(() => {
    if (!authenticated) {
      const searchParams = new URLSearchParams({ returnTo: window.location.pathname }).toString();

      const loginPath = loginPaths[method];

      const href = `${loginPath}?${searchParams}`;

      router.replace(href);
    } else {
      setChecked(true);
    }
  }, [authenticated, method, router]);

  useEffect(() => {
    check();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  if (!checked) {
    return null;
  }

  return <>{hasNoChangedPassword ? <SetPasswordPage /> : children }</>;
}

import { createApi } from '@reduxjs/toolkit/query/react'
import {ICostCenter, ICostCenterDeleteResponse} from "../../types/costCenter";
import {customBaseQuery} from "../../auth/context/jwt/utils";
import { ICostCenterPage } from '../../types/costCenter';

const path: string = 'costCenters'

export const costCenterService = createApi({
    reducerPath: 'costCenterService',
    tagTypes: ['costCenters'],
    baseQuery: customBaseQuery,
    endpoints: (builder) => ({
        getCostCenters: builder.query<ICostCenter[], void>({
            query: () => ({
                url: `/${path}/findAll`,
            }),
            providesTags: ['costCenters']
        }),
        getCostCentersPaginated: builder.query<ICostCenterPage, ICostCenterPage>({
            query: (page : ICostCenterPage) => ({
                url: `/${path}/list`,
                params: { ...page, content: [] }
            }),
            providesTags: ['costCenters']
        }),
        getCostCenter: builder.mutation<ICostCenter, number>({
            query: (id: number) => ({
                url: `/${path}/${id}`,
                method: 'GET'
            })
        }),
        saveCostCenter: builder.mutation<ICostCenter, ICostCenter>({
            query: (costCenter: ICostCenter) => ({
                url: `/${path}/insert`,
                method: 'POST',
                body: costCenter
            }),
            invalidatesTags: ['costCenters']
        }),
        updateCostCenter: builder.mutation<ICostCenter, Partial<ICostCenter>>({
            query: (data: ICostCenter) => ({
                url: `/${path}/update`,
                method: 'PUT',
                body: data
            }),
            invalidatesTags: ['costCenters']
        }),
        deleteCostCenter: builder.mutation<ICostCenterDeleteResponse, number>({
            query: (id: number) => ({
                url: `/${path}/deleteById/${id}`,
                method: 'DELETE'
            }),
            invalidatesTags: ['costCenters']
        })
    }),
})

export const {
    useGetCostCentersQuery,
    useSaveCostCenterMutation,
    useDeleteCostCenterMutation,
    useGetCostCenterMutation,
    useUpdateCostCenterMutation,
    useGetCostCentersPaginatedQuery
} = costCenterService

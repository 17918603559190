export enum Authorities {
    ROLE_ADMIN = 'ADMIN',
    ROLE_BL = 'BL',

    READ_USERS = 'read:users',
    READ_USER = 'read:user',
    EDIT_USER = 'edit:user',
    DELETE_USER = 'delete:user',
    WRITE_USER = 'write:user',

    READ_CUSTOMERS = 'read:customers',
    READ_CUSTOMER = 'read:customer',
    EDIT_CUSTOMER = 'edit:customer',
    DELETE_CUSTOMER = 'delete:customer',
    WRITE_CUSTOMER = 'write:customer',

    READ_SUPPLIERS = 'read:suppliers',
    READ_SUPPLIER = 'read:supplier',
    EDIT_SUPPLIER = 'edit:supplier',
    DELETE_SUPPLIER = 'delete:supplier',
    WRITE_SUPPLIER = 'write:supplier',

    READ_ROLES = 'read:roles',
    READ_ROLE = 'read:role',
    EDIT_ROLE = 'edit:role',
    DELETE_ROLE = 'delete:role',
    WRITE_ROLE = 'write:role',

    // Permission
    READ_PERMISSIONS = 'read:permissions',
    READ_PERMISSION = 'read:permission',
    EDIT_PERMISSION = 'edit:permission',
    DELETE_PERMISSION = 'delete:permission',
    WRITE_PERMISSION = 'write:permission',

    //Account
    READ_ACCOUNTS = 'read:accounts',
    READ_ACCOUNT = 'read:account',
    EDIT_ACCOUNT = 'edit:account',
    DELETE_ACCOUNT = 'delete:account',
    WRITE_ACCOUNT = 'write:account',

    //Account
    READ_COSTCENTERS = 'read:costcenters',
    READ_COSTCENTER = 'read:costcenter',
    EDIT_COSTCENTER = 'edit:costcenter',
    DELETE_COSTCENTER = 'delete:costcenter',
    WRITE_COSTCENTER = 'write:costcenter',

    //Account
    READ_JOURNALS = 'read:journals',
    READ_JOURNAL = 'read:journal',
    EDIT_JOURNAL = 'edit:journal',
    DELETE_JOURNAL = 'delete:journal',
    WRITE_JOURNAL= 'write:journal',


}

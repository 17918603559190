import { forwardRef } from 'react';
// @mui
import { useTheme } from '@mui/material/styles';
import Link from '@mui/material/Link';
import Box, { BoxProps } from '@mui/material/Box';
// routes
import { RouterLink } from 'src/routes/components';

// ----------------------------------------------------------------------

export interface LogoProps extends BoxProps {
  disabledLink?: boolean;
}

const LogoHome = forwardRef<HTMLDivElement, LogoProps>(
  ({ disabledLink = false, sx, ...other }, ref) => {
    const theme = useTheme();

    
    const PRIMARY_LIGHT = theme.palette.primary.light;

    const PRIMARY_MAIN = theme.palette.primary.main;

    const PRIMARY_DARK = theme.palette.primary.dark;


    // OR using local (public folder)
    // -------------------------------------------------------
     const logo = (
         <Box
            component="img"
            src={ theme.palette.mode === 'light' ? "/logo/comprex-logo-single.svg" : "/logo/comprex-logo-single.svg"}
            sx={{ width: 40, height: 40, cursor: 'pointer', ...sx }}
         />
    );

    // const logo = (
    //   <Box
    //     ref={ref}
    //     component="div"
    //     sx={{
    //       width: 40,
    //       height: 40,
    //       display: 'inline-flex',
    //       ...sx,
    //     }}
    //     {...other}
    //   >
    //     <svg 
    //       version="1.0" 
    //       xmlns="http://www.w3.org/2000/svg"
    //       width="100%" 
    //       height="100%" 
    //       viewBox="0 0 512 512"
    //       preserveAspectRatio="xMidYMid meet"
    //     >

    //         <defs>
    //         <linearGradient id="BG1" x1="100%" x2="50%" y1="9.946%" y2="50%">
    //           <stop offset="0%" stopColor={PRIMARY_DARK} />
    //           <stop offset="100%" stopColor={PRIMARY_MAIN} />
    //         </linearGradient>

    //         <linearGradient id="BG2" x1="50%" x2="50%" y1="0%" y2="100%">
    //           <stop offset="0%" stopColor={PRIMARY_LIGHT} />
    //           <stop offset="100%" stopColor={PRIMARY_MAIN} />
    //         </linearGradient>

    //         <linearGradient id="BG3" x1="50%" x2="50%" y1="0%" y2="100%">
    //           <stop offset="0%" stopColor={PRIMARY_LIGHT} />
    //           <stop offset="100%" stopColor={PRIMARY_MAIN} />
    //         </linearGradient>
    //       </defs>

    //       <g 
    //         transform="translate(0.000000,300.000000) scale(0.100000,-0.100000)"
    //         fillRule="evenodd" strokeWidth="1"
    //         fill={PRIMARY_MAIN} stroke="none"
    //       >
    //         <path d="M1281 2744 c-516 -87 -928 -506 -1016 -1034 -23 -132 -16 -355 14
    //         -475 93 -377 327 -672 666 -842 92 -46 248 -99 323 -109 l52 -7 0 399 c0 399
    //         0 399 24 449 30 63 75 109 141 143 41 21 67 27 148 30 l97 4 0 -510 0 -511 90
    //         25 c774 207 1155 1079 782 1788 -182 345 -510 583 -899 651 -105 18 -309 18
    //         -422 -1z m567 -319 c213 -99 363 -282 423 -515 28 -108 25 -348 -5 -377 -5 -6
    //         -114 21 -123 30 -2 2 2 37 8 78 19 122 -1 236 -63 368 -55 115 -159 221 -274
    //         278 -45 22 -87 42 -95 45 -12 4 -12 13 0 59 7 30 16 57 18 62 8 14 34 7 111
    //         -28z m-77 -257 c113 -59 210 -184 244 -313 23 -89 18 -255 -7 -255 -6 0 -35 7
    //         -65 16 l-55 16 5 52 c7 71 -9 151 -45 217 -37 71 -77 110 -154 152 l-60 32 18
    //         63 c22 72 21 72 119 20z m-156 -260 c51 -26 89 -65 111 -112 43 -93 25 -194
    //         -47 -263 -165 -157 -432 -2 -379 219 17 70 96 151 161 166 48 11 122 6 154
    //         -10z"
    //         />
    //       </g>
    //     </svg>




    //   </Box>
    // );

    if (disabledLink) {
      return logo;
    }

    return (
      <Link component={RouterLink} href="/" sx={{ display: 'contents' }}>
        {logo}
      </Link>
    );
  }
);

export default LogoHome;

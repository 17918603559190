import {lazy, Suspense} from 'react';
import {Outlet} from 'react-router-dom';
// auth
import {AuthGuard, RoleBasedGuard} from 'src/auth/guard';
// layouts
import DashboardLayout from 'src/layouts/dashboard';
// components
import {LoadingScreen} from 'src/components/loading-screen';
import {Authorities} from "../../utils/authorities";

// ----------------------------------------------------------------------

// OVERVIEW
const IndexPage = lazy(() => import('src/pages/dashboard/overview'));

// permisssions
const PermissionListPage = lazy(() => import('../../pages/permissions/permissions'));
const CostCenterListPage = lazy(() => import('../../pages/costcenters/costcenters'));
const RoleListPage = lazy(() => import('../../pages/roles/rolelist'));
const RoleNewPage = lazy(() => import('../../pages/roles/rolecreate'));
const RoleEditPage = lazy(() => import('../../pages/roles/roleedit'));

// users
const UserListPage = lazy(() => import('../../pages/users/userlist'));
const UserNewPage = lazy(() => import('../../pages/users/usercreate'));
const UserEditPage = lazy(() => import('../../pages/users/userEdit'));


// ----------------------------------------------------------------------
export const dashboardRoutes = [
    {
        path: '/',
        element: (
            <AuthGuard>
                <DashboardLayout>
                    <Suspense fallback={<LoadingScreen/>}>
                        <Outlet/>
                    </Suspense>
                </DashboardLayout>
            </AuthGuard>
        ),
    },
    {
        path: 'dashboard',
        element: (
            <AuthGuard>
                <DashboardLayout>
                    <Suspense fallback={<LoadingScreen/>}>
                        <Outlet/>
                    </Suspense>
                </DashboardLayout>
            </AuthGuard>
        ),
        children: [
            {
                element: <RoleBasedGuard roles={[Authorities.ROLE_ADMIN]} hasContent> <IndexPage/>
                </RoleBasedGuard>,
                index: true
            },
            {
                path: 'roles',
                children: [
                    {
                        path: 'list',
                        element: <RoleBasedGuard roles={[Authorities.ROLE_ADMIN, Authorities.READ_ROLES]}
                                                 hasContent><RoleListPage/></RoleBasedGuard>
                    },
                    {
                        path: 'new',
                        element: <RoleBasedGuard roles={[Authorities.ROLE_ADMIN, Authorities.WRITE_ROLE]}
                                                 hasContent><RoleNewPage/></RoleBasedGuard>
                    },
                    {
                        path: ':id/edit',
                        element: <RoleBasedGuard roles={[Authorities.ROLE_ADMIN, Authorities.EDIT_ROLE]}
                                                 hasContent><RoleEditPage/></RoleBasedGuard>
                    },
                ],
            },
            {
                path: 'users',
                children: [
                    {
                        path: 'list',
                        element: <RoleBasedGuard roles={[Authorities.ROLE_ADMIN, Authorities.READ_USERS]}
                                                 hasContent><UserListPage/></RoleBasedGuard>
                    },
                    {
                        path: 'new',
                        element: <RoleBasedGuard roles={[Authorities.ROLE_ADMIN, Authorities.WRITE_USER]}
                                                 hasContent><UserNewPage/></RoleBasedGuard>
                    },
                    {
                        path: ':id/edit',
                        element: <RoleBasedGuard roles={[Authorities.ROLE_ADMIN, Authorities.EDIT_ROLE]}
                                                 hasContent><UserEditPage/></RoleBasedGuard>
                    },
                ],
            },
            {
                path: 'permissions',
                children: [
                    {
                        path: 'list',
                        element: <RoleBasedGuard roles={[Authorities.ROLE_ADMIN, Authorities.READ_PERMISSIONS]}
                                                 hasContent><PermissionListPage/></RoleBasedGuard>
                    },
                ],
            },{
                path: 'costcenters',
                children: [
                    {
                        path: 'list',
                        element: <RoleBasedGuard roles={[Authorities.ROLE_ADMIN, Authorities.READ_COSTCENTERS]}
                                                 hasContent><CostCenterListPage/></RoleBasedGuard>
                    },
                ],
            },
        ],
    },
];

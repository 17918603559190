import { Helmet } from 'react-helmet-async';
// sections
import SetPasswordView from '../../../sections/auth/jwt/set-password-view';
import {Stack} from "@mui/material";

// ----------------------------------------------------------------------

export default function SetPasswordPage() {
    return (
        <>
            <Helmet>
                <title> Auth Classic: New Password</title>
            </Helmet>
            <Stack
                sx={{
                    width: 1,
                    mx: 'auto',
                    maxWidth: 490,
                    px: { xs: 2, md: 8 },
                }}
            >
                <SetPasswordView />
            </Stack>

        </>
    );
}
